import React from 'react';
import './about.css'; // Add your styles here
import BadgeGallery from '../components/skills';
const About = () => {
  return (
    <section id='about'>
        <div className="aboutContent">
        <h1 class='title aboutTitle'>About Me</h1>
        <div class='aboutFlex'>
        <p class='abt'>
            Hello! I'm Srinitish Srinivasan, a passionate researcher in Deep Learning and Statistics. My current research interests lie within understanding deep representations. I am interested in working on
            self supervised learning, handling OOD samples and distribution shifts. My skills involve Linear Algebra, Statistics, Calculus, Machine and Deep Learning apart from 
            being proficient in several programming languages and development frameworks such as Python, C, C++, Javascript, 
            PyTorch, Tensorflow, Flask and Django. I love reading new research, connecting with different people 
            and exchanging new ideas. If I am not working on some deep learning model, I am either listening to K-Pop or 
            watching a K-Drama : ))
        </p>
        <img src='profilePic.jpg' class='dp'></img>

        
        </div>

        <div className='center'>
            <h2>Here are some of the tools and frameworks I'm most familiar with!</h2>
            <BadgeGallery/>
        </div>
        
            



            
        </div>
    </section>
  );
};

export default About;
